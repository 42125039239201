import React from 'react';

export const WithoutCompanies = ({handleChange, query}) => {
  return (
    <main id="content">    
      <div className="d-lg-flex align-items-lg-center height-100vh--lg">
        <div className="container space-3-top space-2-bottom space-0--lg">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-6 mb-9 mb-lg-0">
              <img className="w-100" src={'/images/maintenance/maintenance-icon.svg'} alt="" />
            </div>
            <div className="col-lg-5">            
              <div className="mb-4">
                <h1 className="h2">Ninguna empresa encontrada</h1>
                <p className="lead">Intenta buscar con otro nombre.</p>
                
                <input type="text" className="form-control" 
                  value={query} 
                  onChange={handleChange} />                
              </div>                          
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}