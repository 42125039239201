import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './GlobalStyles/themeCore'
import { Routes } from './Routes';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  );
}

export default App;
