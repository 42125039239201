import React, { useState, useReducer, Fragment } from 'react';
import { Form } from './Form';
import axios from 'axios';
// Alert Message
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// Styles
import { styles } from './styles'
import { Button } from '@material-ui/core';
import { HOST } from '../../../Widgets/Utils';

function reducer(form, {field, value}) {
  return {
    ...form, 
    [field]: value
  }
}


export const Contact = ({ name, id, city_id }) => {
  const classes = styles();
  const [open, setOpen] = useState(false);  
  
  const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    company_id: id,
    city_id: city_id
  }
  
  const [form, dispatch] = useReducer(reducer, initialState);
  
  const [openSnack, setOpenSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async e => {
    e.preventDefault()
    await axios.post(`${HOST}/contact`, form)
    .then(res => {   
      setOpen(false);
      setMessageInfo("Mensaje enviado exitosamente");
      setOpenSnack(true);
    })
    .catch(err => console.log('Error', err));
 
  }

  const handleChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value })
    //console.log("Form submit", form)
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Fragment>
      <div className="card text-center" style={{ height: '270px' }}>
        <div className="card-body">
          <h5 className="card-title">Envia un mensaje a la empresa {name}</h5>
          <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>          
          <button type="button" className="btn btn-primary" onClick={handleOpen}> Contactar </button>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>

        <Fade in={open}>
          <div className={classes.paper}>
            <Form 
              initialState={form}
              handleSubmit={handleSubmit}
              handleChange={handleChange} />
          </div>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={messageInfo}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleCloseSnack}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

    </Fragment>
  );
}

// Provide(Pages/Components/CompanyDetail)