import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="bg-dark">
    <div className="container space-2">
      <div className="row justify-content-md-between">        

        <div className="col-6 col-md-3 col-lg-2 order-lg-4 mb-7 mb-lg-0">    
          <h3 className="h6 text-white mb-3">Datos de la empresa</h3>
          {/* <!-- List Group --> */}
          <div className="list-group list-group-flush list-group-transparent">
            <span className="list-group-item list-group-item-action"> <strong>Dirección:</strong> cll 58a #21-27 </span>
            <span className="list-group-item list-group-item-action"> <strong>Teléfonos:</strong> : (+57) 317-8808-817 </span>            
            <span className="list-group-item list-group-item-action"> <strong>Correo electrónico:</strong> : directoriohuelladigital@gmail.com</span>
          </div>
          {/* <!-- End List Group --> */}
        </div>
        
        <div className="col-6 col-md-3 col-lg-2 order-lg-4 mb-7 mb-lg-0">    
          <h3 className="h6 text-white mb-3">Politicas</h3>
          {/* <!-- List Group --> */}
          <div className="list-group list-group-flush list-group-transparent">
            <Link to='/terminos-condiciones' className="list-group-item list-group-item-action"> Terminos &amp; Condiciones</Link>
            <Link to='/politicas-privacidad' className="list-group-item list-group-item-action">Politicas &amp; Privacidad</Link>
          </div>
          {/* <!-- End List Group --> */}
        </div>

        <div className="col-6 col-md-3 col-lg-2 order-lg-6 mb-7 mb-lg-0">
          <h3 className="h6 text-white mb-3">Social</h3>

          {/* <!-- List --> */}
          <div className="list-group list-group-flush list-group-transparent">
            <span className="list-group-item list-group-item-action">
              <i className="fab fa-facebook-f min-width-3 text-center mr-2"></i>
              Facebook
            </span>
            <span className="list-group-item list-group-item-action">
              <i className="fab fa-twitter min-width-3 text-center mr-2"></i>
              Instagram
            </span>            
          </div>
          {/* <!-- End List --> */}
        </div>

        <div className="col-lg-4 order-lg-1 d-flex align-items-start flex-column">
          {/* <!-- Logo --> */}
          <span className="d-inline-block mb-5" aria-label="Space">
            <img src='/images/logos/white-on-transparent.png' alt="Logo" style={{ width: '100px', maxwidth: '100%' }} />             
          </span>
          {/* <!-- End Logo --> */}          
          <p className="small text-muted">Todos los derechos reservados. &copy; 2020 Huella Digital.</p>        
        </div>
      </div>
    </div>
  </footer>
  )
}