import React, { Fragment } from 'react'
import { Company } from './Company';
import { BackgroundImg } from './BackgroundImg';
import { Loader } from '../../Widgets/Loader';
import { PageError } from '../../Widgets/PageError';
import { connect } from "react-redux";
import axios from 'axios';
import { HOST } from '../../Widgets/Utils';

class ListOfCompanies extends React.Component {  
  
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      nextPage: 1,
      loading: false,
      error: null,
      aux_services: [],
      services: [],      
      is_redux: false,
      show_more: true
    };    
  }

  componentDidMount() {    
    this._isMounted = true;
    this.fetchData();    
  }  

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    try {
      axios.get(`${HOST}/companies?page=${this.state.nextPage}`).then((res) => {
        if (this._isMounted) {
          this.setState({ loading: false,
             services: [].concat(this.state.services, res.data),
             nextPage: this.state.nextPage+1
             });
        }
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {    
    if(this.props.current_services !== undefined){
      if (!this.state.is_redux) { //false
        this.setState({ is_redux: !this.state.is_redux }); //true
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    if (this.state.error) {
      return <PageError />
    }
    
    return (      
      <Fragment>      
        <BackgroundImg />
        {this.state.is_redux && this.state.show_more ? 
          <Company companies={this.props.current_services}/>
        :
        <Fragment>
          <Company companies={this.state.services}/>
          <div className="text-center">
            <button className={`btn btn-primary mb-6`} 
                      style={{ width: '200px' }}
                      onClick={() => this.fetchData()}>Ver más</button>
          </div>
        </Fragment>
        }

        <div className="text-center">
          {this.state.show_more &&
            <button className={`btn btn-primary mb-6`} 
                      style={{ width: '200px' }}
                      onClick={() => this.setState({ show_more: !this.state.show_more })}>Ver más</button>
          }
        </div>
      </Fragment>
    )
  }
}


const mapStateToProps = (state) => {  
  return {
    current_services: state.current_filter.initial_state,
  };
};

export default connect(mapStateToProps, null)(ListOfCompanies);