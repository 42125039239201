import React from 'react';
import { FaRegUserCircle } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { styles } from './styles';

export const SignIn = ({ handleSubmit, register, errors }) => {
  const clases = styles()
  return (      
    <form className="js-validate" noValidate="novalidate" onSubmit={handleSubmit} autoComplete="off">
      <div id="signin" data-target-group="idForm" className="animated fadeIn" style={{ opacity: 1 }}>
        <header className="text-center mb-5">
          <h2 className="h4 mb-0">Inicia sesion</h2>
          <p>Podras administar tu cuenta.</p>
        </header>
        {/* <!-- Input --> */}
        <div className="js-form-message mb-3">
          <div className="js-focus-state input-group form">
            <div className="input-group-prepend form__prepend">
              <span className="input-group-text form__text">
                <FaRegUserCircle size='20px'/>
              </span>
            </div>
            <input type="email" 
            className="form-control form__input" 
            name="email"
            ref={register({
              required: "Correo electronico es requerido",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Ingresar correo electronico valido"
              }
            })}
            placeholder="Correo Electronico" 
            aria-label="Email" />
          </div>
        </div>        
        <p className={clases.paragraph}>{errors.email && errors.email.message}</p>

        <div className="js-form-message mb-3">
          <div className="js-focus-state input-group form">
            <div className="input-group-prepend form__prepend">
              <span className="input-group-text form__text">
                <AiFillLock size='20px'/>
              </span>
            </div>
            <input type="password" 
                   className="form-control form__input"
                   name="password" 
                   placeholder="Contraseña" 
                   ref={register({ required: true })}
                   aria-label="Password" />
          </div>
        </div>
        <p className={clases.paragraph}>{errors.password && "Contraseña es requerida"}</p>


        {/* <!-- End Input --> */}
        <div className="row mb-3">
          <div className="col-6">
            <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
              <input type="checkbox" className="custom-control-input" id="rememberMeCheckbox" />
              <label className="custom-control-label" htmlFor="rememberMeCheckbox">
                Recordarme
              </label>
            </div>
          </div>
          <div className="col-6 text-right">            

            <a href="https://api-huella-digital.herokuapp.com/companies/password/new" 
               target="_blank" rel="noopener noreferrer"
              className="js-animation-link float-right link-primary">¿Olvidaste tu contraseña?</a> 

          </div>
        </div>
        <div className="mb-3">
          <button type="submit" className="btn btn-block btn-primary">Iniciar sesion</button>          
        </div>

      </div>      
    </form>
       
  )
}

// Provide(Pages/Header/index.js)