import React, { Fragment } from 'react';
import { Suscribe } from './Suscribe';
import Slide from 'react-reveal/Slide';

export const Testimonies = ({ testimonial_title, testimonial_description, testimonial_image_one,
  testimonial_name_one, testimonial_description_one, text_social_media_one, testimonial_social_media_one,
  testimonial_image_two, testimonial_name_two, testimonial_description_two, text_social_media_two, testimonial_social_media_two,
  testimonial_image_three, testimonial_name_three, testimonial_description_three, text_social_media_three, testimonial_social_media_three, get_started_hide
 }) => {
  return (
    <Fragment>
      {get_started_hide && 
        <Suscribe />
      }
      <div className="bg-gray-100">
        <div className="container space-3-top space-2-bottom space-4-top--lg space-3-bottom--lg">
          {/* <!-- Title --> */}
          <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">            
            <h2 className="h3">{testimonial_title}</h2>
            <p>{testimonial_description}</p>
          </div>
          {/* <!-- End Title --> */}

          <div className="card-deck d-block d-lg-flex">
            {/* <!-- Testimonials --> */}

            {testimonial_name_one !== "" && testimonial_description_one !== "" ?
              <Slide down>
                <div className="card card-frame mb-5 mb-lg-0">
                  <div className="card-body p-5">
                    <blockquote className="text-secondary mb-0">{testimonial_description_one}</blockquote>
                  </div>
                  <div className="card-footer">
                    <div className="media align-items-center">
                      <div className="position-relative mr-4">
                        <img className="u-md-avatar rounded-circle" src={testimonial_image_one} alt="" />
                        <span className="badge badge-sm badge-primary badge-icon badge-pos badge-pos--bottom-right-minus-1 rounded-circle">
                          <i className="fab fa-twitter"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h4 className="h6 mb-0">{testimonial_name_one}</h4>
                        <span className="d-block text-muted font-size-14 font-weight-normal"><a className="link-primary" href={testimonial_social_media_one} target="_blank" rel="noopener noreferrer">{text_social_media_one}</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              :
              <Slide down>
                <div className="card card-frame mb-5 mb-lg-0" style={{ visibility: 'hidden' }}/>
              </Slide>
            }
            {/* <!-- End Testimonials --> */}
            
            {testimonial_name_two !== "" && testimonial_description_two !== "" ?
              <Slide down>
                <div className="card card-frame mb-5 mb-lg-0">
                  <div className="card-body p-5">
                    <blockquote className="text-secondary mb-0">{testimonial_description_two}</blockquote>
                  </div>
                  <div className="card-footer">
                    <div className="media align-items-center">
                      <div className="position-relative mr-4">
                        <img className="u-md-avatar rounded-circle" src={testimonial_image_two} alt=""/>
                        <span className="badge badge-sm badge-primary badge-icon badge-pos badge-pos--bottom-right-minus-1 rounded-circle">
                          <i className="fab fa-facebook-f"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h4 className="h6 mb-0">{testimonial_name_two}</h4>
                        <span className="d-block text-muted font-size-14 font-weight-normal"><a className="link-primary" href={testimonial_social_media_two} target="_blank" rel="noopener noreferrer">{text_social_media_two}</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              :  
              <Slide down>
                <div className="card card-frame mb-5 mb-lg-0" style={{ visibility: 'hidden' }}/>
              </Slide>
            }

            {/* <!-- Testimonials --> */}
            {testimonial_name_three !== "" && testimonial_description_three !== "" ?
              <Slide down>
                <div className="card card-frame">
                  <div className="card-body p-5">
                  <blockquote className="text-secondary mb-0">{testimonial_description_three}</blockquote>
                  </div>
                  <div className="card-footer">
                    <div className="media align-items-center">
                      <div className="position-relative mr-4">
                        <img className="u-md-avatar rounded-circle" src={testimonial_image_three} alt=""/>
                        <span className="badge badge-sm badge-primary badge-icon badge-pos badge-pos--bottom-right-minus-1 rounded-circle">
                          <i className="fab fa-twitter"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h4 className="h6 mb-0">{testimonial_name_three}</h4>
                        <span className="d-block text-muted font-size-14 font-weight-normal"><a className="link-primary" href={testimonial_social_media_three} target="_blank" rel="noopener noreferrer">{text_social_media_three}</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              :  
              <Slide down>
                <div className="card card-frame mb-5 mb-lg-0" style={{ visibility: 'hidden' }}/>
              </Slide>
            }
            {/* <!-- End Testimonials --> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}