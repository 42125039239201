import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  link: {
    paddingTop: '1.375rem',
    paddingBottom: '1.375rem',
    paddingRight: '0.875rem',
    paddingLeft: '0.875rem',
    textDecoration: 'none'
  },
  textWhite: {
    color: 'rgba(255, 255, 255, 0.9)'
  },
  textBlack: {
    color: 'rgb(0 0 0)'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '40%'
  },
}))

export const drawerStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  divButtonClose: {
    marginBottom: '20px'
  },
  buttonClose: {
    position: 'absolute',
    right: 0,
    margin: '10px'
  },
  colorText: {
    color: '#000000'
  }
});
