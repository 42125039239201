import React, { Fragment } from 'react';

export const Services = ({ categories }) => {
  return (
    <Fragment>            
      <h3 className="mb-3"> Servicios </h3>      
       {        
        categories.map((category) =>        
          <div className="col-md-6 col-sm-12" key={category.id}>
            <div className="card card-frame mb-5"> 
              <span className="card-body p-4">
                <div className="media">          
                  <div className="media-body px-4">
                    <h4 className="h6 text-dark mb-1">{category.name}</h4>
                  </div>
                </div>
              </span>
            </div>
          </div>           
        )}        
    </Fragment>
  )
}

// Provide(Pages/CompanyDetailContainer/CompanyDetail)