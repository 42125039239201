import React from 'react'
import { styles } from './styles'

export const BackgroundImg = () => {
  const clases = styles();
  return (
    <div className={clases.background}>
      <div className="container text-center space-2-top space-3-bottom space-3-top--lg">
        <div className="w-md-80 mx-md-auto mb-7">
          <h1 className="display-3 font-size-48--md-down text-white mb-4">Nuestras empresas</h1>
          <p className="lead text-white font-weight-light">Servicios que cada una de ellas te ofrece.</p>
        </div>        
      </div>
    </div>
  )
}