import React, { useEffect, useState, Fragment } from 'react';
import { useStyles } from './styles';
import { SignIn } from '../Components/UserForm/SignIn'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { HeaderItem } from './HeaderItem';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { HOST } from '../../Widgets/Utils';
import { useHistory } from "react-router-dom";
// Alert Message
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';

export const Header = () => {
    
  const history = useHistory();
  const [scrollTop, setScrollTop] = useState(0);
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);    
  
  const [openSnack, setOpenSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);  

  const classes = useStyles();

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return window.addEventListener("scroll", onScroll);
  }, [scrollTop]);

  const onScroll = scrollTop > 10 ? "u-header__section__white" : "u-header__section"

  const Logo = scrollTop > 10 ? '/images/logos/black-on-transparent.png' : '/images/logos/white-on-transparent-header.png'
  
  const textColor = scrollTop > 10 ? classes.textBlack : classes.textWhite


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async data => {    
    await axios.post(`${HOST}/login`, data)
    .then(res => { 
      if (res.data.status === 411) {
        setMessageInfo("Email o Contraseña son invalidos");
        setOpenSnack(true);
      }else {        
        localStorage.setItem('client-hd', res.data.id);
        localStorage.setItem('authentication_token', res.data.authentication_token);
        history.push(`/administrador`);
      }
    })
    .catch(err => {
      console.log(err);
    })
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  return (      
    <Fragment>
    <header id="header" className="u-header u-header--modern u-header--bordered u-header--bg-transparent u-header--white-nav-links u-header--sticky-top-lg">
    
      <HeaderItem 
        onScroll={onScroll}
        Logo={Logo} 
        textColor={textColor}
        handleOpen={handleOpen}/>

    </header>   


    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>

        <Fade in={open}>
          <div className={classes.paper}>            
              <SignIn
              register={register}
              errors={errors}
              handleSubmit={handleSubmit(onSubmit)} 
              />            
          </div>
        </Fade>

      </Modal>

      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={messageInfo}
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={handleCloseSnack}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

    </Fragment>  
  )
}