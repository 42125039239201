import React, { useState, useMemo } from 'react';
import { ItemCompany } from './ItemCompany';
import { WithoutCompanies } from './WithoutCompanies';


function useSearchCompanies(companies) {
  const [query, setQuery] = useState('')

  const [filteredCompanies, setFilteredCompanies] = useState(companies)

  useMemo(() => {
      const result = companies.filter(company => {
        return company.name.toLowerCase().includes(query.toLowerCase())
      });
      setFilteredCompanies(result)
    }, [companies, query]);
    
    return { query, setQuery , filteredCompanies}
}


export const Company = ( { companies } ) => {

  const { query, setQuery, filteredCompanies } = useSearchCompanies(companies)


  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  if(filteredCompanies.length === 0){
    return (        
        <WithoutCompanies query={query} handleChange={handleChange} />    
    );
  }

  return (   
    <ul className="list-unstyled mt-9">
      <div className="container form-group">
        <label>Buscar Empresas</label>
        <input type="text" 
          className="form-control" 
          placeholder="Ingresa nombre de la empresa"
          value={query} 
          onChange={(e) => {
            setQuery(e.target.value)
          }} />
      </div>

      {filteredCompanies.map((company)=>{      
        return(
            <li key={company.id}>
                <ItemCompany company={company}/>                
            </li>
        );
      })}
    </ul>
  )
     
}