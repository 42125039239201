import React from 'react';
import './styles.css';

export const Loader = () => {  
  return (
    <div className="grid">
      <div className="loader">
        <div id="ld3">
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}