import React from 'react';

export const ContactSend = ({handleChangeContact}) => (
  <main id="content">    
    <div className="d-lg-flex align-items-lg-center height-100vh--lg">
      <div className="container space-3-top space-2-bottom space-0--lg">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-6 mb-9 mb-lg-0">
            <img className="w-100" src={'/images/maintenance/subscribe-primary-icon.svg'} alt="" />
          </div>
          <div className="col-lg-5">            
            <div className="mb-4">
              <h1 className="h2">Mensaje enviado satisfactoriamente</h1>
              <p className="lead">En poco nos contactaremos contigo</p>
                            
              <button className="btn btn-primary" onClick={handleChangeContact}>Contactar de nuevo</button>
            </div>                          
          </div>
        </div>
      </div>
    </div>
  </main>
)