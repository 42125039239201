import React from 'react';
import { GrAchievement } from "react-icons/gr";

export const EmailSuccess = () => (
  <main id="content">  
    <div className="d-md-flex align-items-md-center height-100vh--md">
      <div className="container text-center space-2 space-3--lg">
        <div className="w-md-80 w-lg-60 text-center mx-md-auto">
          <div className="mb-5">
            <span className="u-icon u-icon--secondary-success u-icon--xl rounded-circle mb-4">              
              <GrAchievement className="u-icon__inner" />
            </span>
            <h1 className="h2">Contraseña actualizada</h1>
            <p>Tu contraseña fue actualizada exitosamente</p>            
          </div>          
        </div>
      </div>
    </div>
  </main>
)