import React from 'react';

export const DeleteModal = ({ handleDelete, handleClose }) => {
  return (
  <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
    <h1>Seguro?</h1>
    <p>Estas seguro de eliminar este mensaje</p>

    <div>
      <button onClick={handleDelete} className="btn btn-danger mr-4">
        Eliminar
      </button>
      <button onClick={handleClose} className="btn btn-primary">
        Cancelar
      </button>
    </div>
  </div> 
  )
}