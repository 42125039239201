import React, { Fragment } from "react";
import Fade from 'react-reveal/Fade';

export const Companies = ({ companies_title, companies_description, icon_company_one, title_company_one, description_company_one,
  icon_company_two, title_company_two, description_company_two, icon_company_three, title_company_three, description_company_three }) => {
  
  return (    
    <Fragment>      
      <div className="container space-2 space-3--lg">
        {/* <!-- Title --> */}
        <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">            
          <h2 className="h3">{companies_title}</h2>
          <p>{companies_description}</p>
        </div>
        {/* <!-- End Title --> */}

        <div className="card-deck d-block d-lg-flex card-lg-gutters-2">
          <div className="card border-0 mb-3">
            {/* <!-- Works --> */}
            <Fade left>
              <div className="card-body border border-bottom-0 rounded-top text-center p-5">
                <img className="u-md-avatar-companies rounded mb-4" src={icon_company_one} alt="" />
                <h4 className="h5 mb-1">{title_company_one}</h4>
                <p className="mb-0">{description_company_one}</p>
              </div>
            </Fade>
            {/* <!-- End Works --> */}
          </div>

          <div className="card border-0 mb-3">
            {/* <!-- Works --> */}
            <Fade left>
              <div className="card-body border border-bottom-0 rounded-top text-center p-5">
                <img className="u-md-avatar-companies rounded mb-4" src={icon_company_two} alt="" />
                <h4 className="h5 mb-1">{title_company_two}</h4>
                <p className="mb-0">{description_company_two}</p>
              </div>
            </Fade>
            {/* <!-- End Works --> */}
          </div>

          <div className="card border-0 mb-3">
            {/* <!-- Works --> */}
            <Fade left>
              <div className="card-body border border-bottom-0 rounded-top text-center p-5">
                <img className="u-md-avatar-companies rounded mb-4" src={icon_company_three} alt="" />
                <h4 className="h5 mb-1">{title_company_three}</h4>
                <p className="mb-0">{description_company_three}</p>
              </div>
            </Fade>
            {/* <!-- End Works --> */}
          </div>
          
        </div>
      </div>
    </Fragment>
  )
}