import React from "react";
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';
import { Information } from "./Information";

import axios from 'axios';
import { HOST } from "../../../Widgets/Utils";
import { Loader } from "../../../Widgets/Loader";
import { PageError } from "../../../Widgets/PageError";

class AboutUs extends React.Component {  
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      loading: false,
      error: null,
      data: { }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    try {      
      axios.get(`${HOST}/about_us`).then((res) => {
        if (this._isMounted) {
          this.setState({ loading: false, data: res.data });
        }
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {  

    if (this.state.loading) {
      return <Loader />
    }

    if (this.state.error) {
      return <PageError />
    }
    
    
    const { text_brand_one, text_brand_two, text_brand_three, complement, description, title_body,
      description_body, component_title_one, component_description_one, component_title_two, component_description_two,
      component_title_three, component_description_three, component_title_four, component_description_four,
      image_principal, component_image_one, component_image_two, component_image_three, component_image_four } = this.state.data

    return (
      <React.Fragment>
      <div className="bg-img-hero gradient-overlay-half-dark-v2" style={{ backgroundImage: `url(${image_principal})` }}>
        <div className="d-lg-flex align-items-lg-center height-100vh--lg">
          <div className="container text-center space-2 space-0--lg">
            <h1 className="display-3 font-size-48--md-down text-white mb-4">
            
              <Typed
                    strings={[
                    `${text_brand_one}`,
                    `${text_brand_two}`,
                    `${text_brand_three}`]}
                    typeSpeed={40} 
                    backSpeed={50}                    
                  />              
              {complement}
            </h1>

            <div className="w-md-75 w-lg-60 mx-md-auto">
              <p className="lead text-white">{description}</p>
            </div>
          </div>
        </div>

        <div className="position-absolute-bottom-0--lg bg-white z-index-2">
          <div className="container space-1">

          </div>
        </div>
      </div>  

      <Information title_body={title_body} description_body={description_body} 
                   component_image_one={component_image_one} component_title_one={component_title_one} 
                   component_description_one={component_description_one}
                   component_image_two={component_image_two} component_title_two={component_title_two}
                   component_description_two={component_description_two} 
                   component_image_three={component_image_three} component_title_three={component_title_three} 
                   component_description_three={component_description_three}
                   component_image_four={component_image_four} component_title_four={component_title_four} 
                   component_description_four={component_description_four} />
          
      {/* <AccordionInformation /> */}

    </React.Fragment>  
    );
  }
}

export default AboutUs;


