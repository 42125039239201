import React, { useRef, useEffect, useState } from 'react';
import { Layout } from '../../Layout';
import axios from 'axios';
// Table
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { TableContent } from './TableContent';
// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// styles
import { styles } from './styles'
import { DeleteModal } from './DeleteModal';
import { HOST } from '../../../Widgets/Utils';
import { PageError } from '../../../Widgets/PageError';
import { useHistory } from "react-router-dom";

export const Messages = () => {
    
  let currentPath = window.location.pathname;
  const history = useHistory();
  const [data, setData] = useState(null);
  const isMountedRef = useRef(null);
  const [Id, setId] = useState(null);
  const [error, setError] = useState(null);
  const classesModal = styles();    
  const [open, setOpen] = useState(false);  
  const clienId = localStorage.getItem('client-hd');

  let rows = [];
  if (data) {
    rows = data;
  }
  
  const columns = [
    { id: 'name', label: 'Nombre', minWidth: 170 },
    { id: 'phone', label: 'Numero telefono', minWidth: 170 },
    { id: 'email', label: 'Correo electronico', minWidth: 170 },
    { id: 'message', label: 'Mensaje', minWidth: 170 },
    { id: 'delete', label: 'Eliminar', minWidth: 170 },
  ];

  const handleOpen = () => {
    setOpen(true);    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIsDelete = (Id) => {
    setId(Id)
  }  

  const handleDelete = async e => {      
    await axios.delete(`${HOST}/contact?id=${Id}`)
    .then(res => {
      setOpen(false);
      history.replace(`${currentPath}/replace`);
      setTimeout(() => {
          history.replace(currentPath)
      }, 0)
    })
    .catch(err => {
      setError(err);
    });
  }
  

  useEffect(() => {
    isMountedRef.current = true;              
    const fetchData = async () => {        
      axios.get(`${HOST}/company?id=${clienId}`)
      .then(res => {if(isMountedRef.current){            
          setData(res.data.contacts);          
        }})
      .catch(err => console.log('Error', err));              
    };
    fetchData();
    return () => isMountedRef.current = false;  
  }, [clienId]);


  if (error) {
    return <PageError error={error}/>;
  }    
  
  return (
    <Layout>      
      <TableContainer component={Paper} className="container">
        <TableContent rows={rows}  
                      columns={columns} 
                      handleOpen={handleOpen} 
                      handleIsDelete={handleIsDelete}/>
      </TableContainer>

      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesModal.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

        <Fade in={open}>
          <div className={classesModal.paper}>
              <DeleteModal 
                handleClose={handleClose}
                handleDelete={handleDelete}/>
            </div>
          </Fade>
        </Modal>  

    </Layout>
  )
}