import React, { Fragment } from 'react';
import { Links } from './Links';
import { Link } from 'react-router-dom';
import { drawerStyles } from './styles';
// DrawerMenu
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

export const HeaderItem = ({ onScroll, Logo, textColor, handleOpen }) => {  
  const classes = drawerStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const list = (anchor) => (
    <Fragment>
      <div className={classes.divButtonClose} onClick={toggleDrawer('left', false)}>
        <HighlightOffIcon className={classes.buttonClose} />
      </div>

      <div className="text-center mt-5 mb-3">
        <img src='/images/logos/monochrome-on-transparent.png' alt="LogoResponsive" style={{ width: '100px', maxwidth: '100%' }} />
      </div>

      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        
        <List>        
          {/* Home */}
          <ListItem button>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText>
              <Link to="/" className={classes.colorText}>
                Home
              </Link>
            </ListItemText>
          </ListItem>

          {/* Sobre nosotros */}
          <ListItem button>            
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText> 
              <Link to="/lista-empresas" className={classes.colorText}>
                Ver empresas
              </Link>
            </ListItemText>
          </ListItem>   

          {/* Sobre nosotros */}
          <ListItem button>            
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText> 
              <Link to="/sobre-nosotros" className={classes.colorText}>
                Sobre nosotros
              </Link>
            </ListItemText>
          </ListItem>          

        </List>
        <Divider />
        <List>
          <ListItem button>                        
            <button type="submit" className="btn btn-block btn-primary">Iniciar sesion</button>            
          </ListItem> 
        </List>
      </div>
    </Fragment>
  );

  return (
    <div className={onScroll}>
      <div id="logoAndNav" className="container">
        {/* <!-- Nav --> */}
        <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar">
          {/* <!-- Logo --> */}
          <div className="u-header__navbar-brand-wrapper">
            <Link to="/" className="navbar-brand u-header__navbar-brand">
              <img className="u-header__navbar-brand-default" src={Logo} alt="Logo" />
            </Link>
          </div>
          {/* <!-- End Logo --> */}

          {/* <!-- Responsive Toggle Button --> */}
          <div className="navbar-toggler btn u-hamburger u-header__hamburger" >            
            <div>
              {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
                  <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </div>
          {/* <!-- End Responsive Toggle Button --> */}

          {/* <!-- Navigation --> */}
          <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse py-0">
            <ul className="navbar-nav u-header__navbar-nav">
              {/* <!-- Start Links --> */}
                <Links textColor={textColor} />              
              {/* <!-- End Link --> */}
              
              <li className="nav-item u-header__nav-item-btn">                  
                <button type="button" className="btn btn-sm btn-primary" onClick={handleOpen}> Iniciar sesion </button>
              </li>
            </ul>
          </div>
          {/* <!-- End Navigation --> */}
        </nav>
        {/* <!-- End Nav --> */}
      </div>
    </div>  
  )
}

// Provide (Pages/Components/Header/index)