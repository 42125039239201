import React from 'react';
import { styles } from './styles';
import '../../Responsive/styles.css';

export const Categories = ({ categories, handleFilter }) => { 
  const clases = styles()
  return (
    <div className={`categories-sm ${clases.background}`}>
      <div className="container">            
        <div className="row">
          {categories.map((category) => 
            <div className="col-md-3" key={category.id} onClick={() => handleFilter(category.id)}>
              <span className="js-fancybox u-media-player media align-items-center text-white">
                <span className="u-media-player__icon mr-3">                  
                  <img src={category.logo} className="fa fa-play u-media-player__icon-inner" alt=""/>
                </span>
                <span className="media-body">
                  <small className="d-block text-uppercase">{category.name}</small>
                </span>
              </span>                    
            </div>
          )}
        </div>
      </div>
    </div>   
  )
}
// Provide( Components/Hero )