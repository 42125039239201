import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../Responsive/styles.css';

export const Form = ({ handleSubmit, handleChangeCategory, handleChangeCity, categories, cities }) => {

  return (
    <form className="row align-items-md-center" onSubmit={handleSubmit} autoComplete="off">
      <div className="col-sm-6 col-lg-4 responsive-ac">          
        <Autocomplete
          id="combo-box-demo"
          options={cities}
          getOptionLabel={(option) => option.name}        
          onChange={handleChangeCity}  
          renderInput={(params) => <TextField {...params} label="Selecciona Ciudad" variant="outlined" />}
        />
      </div>
      <div className="col-sm-6 col-lg-6 responsive-ac">
        <Autocomplete
            id="combo-box-demo-categories"
            options={categories}
            getOptionLabel={(option) => option.name}        
            onChange={handleChangeCategory}  
            renderInput={(params) => <TextField {...params} label="Selecciona Categorias" variant="outlined" />}
          />
      </div>
      <div className="col-lg-2 text-lg-right">        
        <button className="btn btn-block btn-primary">Buscar</button>
      </div>
    </form>
  )
}