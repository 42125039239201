import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => (
  <main id="content">  
    <div className="bg-gray-100">
      <div className="d-flex align-items-center height-100vh">
        <div className="container text-center">
          <div className="w-lg-60 mx-lg-auto">                      
            <img className="w-100" src="/images/maintenance/error-404.svg" alt="" />
            {/* <!-- Title --> */}
            <h1 className="display-3 font-size-48--md-down font-weight-medium mb-3">Pagina no encontrada</h1>
            <p className="lead mb-0">Oops! Parece que seguiste mal un enlace.</p>
            <Link to="/" className="btn btn-primary">
              Ir a la pagina oficial
            </Link>
          </div>
        </div>
      </div>
    </div>
  </main>
)