import React, { Fragment } from "react";
import axios from "axios";
import { CompanyDetail } from "./CompanyDetail";
import { HOST } from "../../../Widgets/Utils";
import { Loader } from "../../../Widgets/Loader";
import { PageError } from "../../../Widgets/PageError";

class CompanyDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      loading: false,
      error: null,
      showCompany: {
        categories: [],
      }       
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    try {
      let compnayId = this.props.match.params.companyId;
      axios.get(`${HOST}/company?id=${compnayId}`).then((res) => {
        if (this._isMounted) {
          this.setState({ loading: false, showCompany: res.data });
        }        
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    if (this.state.error) {
      return <PageError />
    }
    
    return (
      <Fragment>
        <CompanyDetail showCompany={this.state.showCompany}/>
      </Fragment>
    );
  }
}

export default CompanyDetailContainer;