import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Pages
import { Home } from '../Pages/Components/Home';
import AboutUs from '../Pages/Components/AboutUs';

// Components
import { Layout } from '../Pages/Layout';
import ListOfCompanies from '../Pages/ListOfCompanies';
import CompanyDetailContainer from '../Pages/Components/CompanyDetailContainer';
import { PrivacyPolicy } from '../Pages/Components/Utilies/PrivacyPolicy';
import { TermsConditions } from '../Pages/Components/Utilies/TermsConditions';
import { NotFound } from '../Pages/NotFound';
import { Instructions } from '../Pages/Email/Instructions';
import { EmailSuccess } from '../Pages/Email/EmailSuccess';

//Admin
import { Messages } from '../Companies/Components/Messages';
import NewCompany from '../Companies/Components/NewCompany';

// React Redux
import store from '../redux/store';
import { Provider } from 'react-redux';

export const Routes = () => {
  return (    
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/administrar-empresa" component={NewCompany} />
          <Route exact path="/administrador" component={Messages} />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/sobre-nosotros" component={AboutUs}/>
              <Route exact path="/lista-empresas" component={ListOfCompanies} />
              <Route exact path="/empresa/:companyId" component={CompanyDetailContainer}/>
              <Route exact path="/politicas-privacidad" component={PrivacyPolicy}/>
              <Route exact path="/terminos-condiciones" component={TermsConditions}/>
              <Route exact path="/enviado-correctamente-instrucciones-recuperacion" component={Instructions}/>
              <Route exact path="/cambiada-contrasena-correctamente" component={EmailSuccess}/>
              <Route component={NotFound} />
            </Switch>
          </Layout>         
        </Switch>           
      </BrowserRouter>
    </Provider>
    )
}