import React, { Fragment, useEffect, useRef, useState } from 'react';
import Hero from './Hero';
import { Benefits } from './Benefits';
import { Companies } from './Companies';
import { Testimonies } from './Testimonies';
import PrincipalFilter from './PrincipalFilter';
import { Contact } from './Contact';
import axios from 'axios';
import { HOST } from '../../Widgets/Utils';
import { Loader } from '../../Widgets/Loader';
import { PageError } from '../../Widgets/PageError';

export const Home = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;     
    setLoading(true);
    const fetchData = async () => {        
      axios.get(`${HOST}/landing_page`)
      .then(res => {if(isMountedRef.current){
          setData(res.data);
          setLoading(false);
        }})
      .catch(err => {
          setError(err);
          setLoading(false);
      })
    };
    fetchData();
    return () => isMountedRef.current = false;  
  }, []);  


  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <PageError error={error}/>;
  }    
   
  const {photo_slider, title_main, description_main, benefits_hide, benefits_title, benefits_description, icon_benefit_one,
    title_benefit_one, description_benefit_one, icon_benefit_two, title_benefit_two,
    description_benefit_two, icon_benefit_three, title_benefit_three, description_benefit_three, companies_hide, 
    companies_title, companies_description, icon_company_one,
    title_company_one, description_company_one, icon_company_two, title_company_two, description_company_two,
    icon_company_three, title_company_three, description_company_three, testimonials_hide,
    testimonial_title, testimonial_description, testimonial_image_one, testimonial_name_one, testimonial_description_one,
    text_social_media_one, testimonial_social_media_one, testimonial_image_two, testimonial_name_two, 
    testimonial_description_two, text_social_media_two, testimonial_social_media_two, testimonial_image_three,
    testimonial_name_three, testimonial_description_three, text_social_media_three, testimonial_social_media_three,
    contact_title, contact_subtitle, get_started_hide
  } = data

  return (        
    <Fragment>            
      <Hero title_main={title_main} 
            description_main={description_main}
            photo_slider={photo_slider} />
      <PrincipalFilter />
      {benefits_hide &&
        <Benefits benefits_title={benefits_title} 
                  benefits_description={benefits_description}
                  icon_benefit_one={icon_benefit_one}
                  title_benefit_one={title_benefit_one}
                  description_benefit_one={description_benefit_one}
                  icon_benefit_two={icon_benefit_two}
                  title_benefit_two={title_benefit_two}
                  description_benefit_two={description_benefit_two}
                  icon_benefit_three={icon_benefit_three}
                  title_benefit_three={title_benefit_three}
                  description_benefit_three={description_benefit_three}
                   />
      }
      {companies_hide && 
        <Companies companies_title={companies_title} 
                   companies_description={companies_description}
                   icon_company_one={icon_company_one}
                   title_company_one={title_company_one}
                   description_company_one={description_company_one}
                   icon_company_two={icon_company_two}
                   title_company_two={title_company_two}
                   description_company_two={description_company_two}
                   icon_company_three={icon_company_three}
                   title_company_three={title_company_three}
                   description_company_three={description_company_three}
                   />
      }
      {testimonials_hide &&
        <Testimonies testimonial_title={testimonial_title}
                     testimonial_description={testimonial_description}
                     testimonial_image_one={testimonial_image_one} 
                     testimonial_name_one={testimonial_name_one}
                     testimonial_description_one={testimonial_description_one}
                     text_social_media_one={text_social_media_one}
                     testimonial_social_media_one={testimonial_social_media_one}                     
                     testimonial_image_two={testimonial_image_two} 
                     testimonial_name_two={testimonial_name_two}
                     testimonial_description_two={testimonial_description_two}
                     text_social_media_two={text_social_media_two}
                     testimonial_social_media_two={testimonial_social_media_two}
                     testimonial_image_three={testimonial_image_three} 
                     testimonial_name_three={testimonial_name_three}
                     testimonial_description_three={testimonial_description_three}
                     text_social_media_three={text_social_media_three}
                     testimonial_social_media_three={testimonial_social_media_three}
                     get_started_hide={get_started_hide} />
      }
      <Contact contact_title={contact_title} contact_subtitle={contact_subtitle}/>
    </Fragment>
  )
}