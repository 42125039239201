import React from 'react';
import { styles } from './styles';

export const Form = ({ handleSubmit, register, errors }) => {
  const clases = styles()

  return (
    <form className="js-validate" autoComplete="off" onSubmit={handleSubmit} >
      <div className="row">    
        <div className="col-sm-6 mb-6">
          <div className="js-form-message">
            <div className="js-focus-state input-group form">
              <input className="form-control form__input" 
                    type="text" 
                    name="name" 
                    placeholder="Tu nombre"
                    ref={register({ required: true })}
                    />
            </div>            
            <p className={clases.paragraph}>{errors.name && "Ingresa tu nombre"}</p>            
          </div>
        </div>
        
        <div className="col-sm-6 mb-6">
          <div className="js-form-message">
            <div className="js-focus-state input-group form">
              <input className="form-control form__input"
                     type="email" 
                     placeholder="Tu correo electrónico"
                     name="email" 
                     ref={register({
                        required: "Ingresa un correo electronico",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Correo electronico invalido"
                        }
                      })}/>
                    
            </div>
            {errors.email && <p className={clases.paragraph}>{errors.email.message}</p>}
          </div>
        </div>

        <div className="w-100"></div>

        <div className="col-sm-6 mb-6">
          <div className="js-form-message">
            <div className="js-focus-state input-group form">
              <input className="form-control form__input" 
                    type="text" 
                    name="subject"
                    placeholder="Ingresa un motivo"
                    ref={register} />
            </div>
          </div>
        </div>

        <div className="col-sm-6 mb-6">
          <div className="js-form-message">
            <div className="js-focus-state input-group form">
              <input className="form-control form__input" 
                    type="number" 
                    name="phone"
                    placeholder="Tu número de teléfono"
                    ref={register} />
            </div>
          </div>
        </div>
      </div>

      <div className="js-form-message mb-9">
        <div className="js-focus-state input-group form">
          <textarea className="form-control form__input" 
                    rows="6"
                    name="message"
                    placeholder="¿Cómo podemos ayudarte?"
                    ref={register({ required: true })}
                     ></textarea>
          
        </div>        
        <p className={clases.paragraph}>{errors.message && "Ingresa una descripción"}</p>               
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-primary btn-wide mb-4">Enviar</button>
      </div>
    </form>
  )
}


// Provide(Pages/Components/Contact/index)