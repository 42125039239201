/*eslint-disable */
import React, { Fragment } from 'react';
import { styles } from './styles';
import { Contact } from './Contact';
import { About } from './About';
import { CarouselPhotos } from './CarouselPhotos';
import { Services } from './Services';

export const CompanyDetail = ({ showCompany }) => {
  const clases = styles();
 
  const { id, address, city_id,  description, facebook, linkedin, img1, img2, img3, 
    instagram, lat, lng, name, logo, city, twitter, web_page, categories } = showCompany
          
  return (       
    <div className="container">
      <div className="p-5">
        <h3 className="mb-3 text-center">
          {name}
        </h3>            
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ height: '270px'}}>
            <img src={logo} className={clases.image} />
          </div>
        </div>

        <div className="col-md-5 offset-md-1">
          <Contact name={name} id={id} city_id={city_id} />
        </div>
      </div>

      <div className="w-50 w-lg-100 mx-auto mt-5 mb-5">
        <hr className="my-0" />
      </div>
      {/* About */}

      <About description={description}/>      

      <div className="col-md-6">
        <div className="row no-gutters">
          {facebook &&
            <div className="col">
              <a href={facebook} target="_blank" rel="noopener noreferrer">
                <img src='/images/social-media/icon-facebook.png' className="card-img" alt="facebook" style={{ width: '30%' }} />
                <p className="d-inline ml-2">Facebook</p>
              </a>
            </div>
          }

          {instagram &&
            <div className="col">
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                <img src='/images/social-media/icon-instagram.png' className="card-img" alt="instagram" style={{ width: '30%' }} />
                <p className="d-inline ml-2">Instagram</p>
              </a>
            </div>
          }

          {twitter &&
            <div className="col">
              <a href={twitter} target="_blank" rel="noopener noreferrer">
                <img src='/images/social-media/icon-twitter.png' className="card-img" alt="twitter" style={{ width: '30%' }} />
                <p className="d-inline ml-2">Twitter</p>
              </a>
            </div>
          }

          {linkedin &&
            <div className="col">
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                <img src='/images/social-media/icon-linkedin.png' className="card-img" alt="linkedin" style={{ width: '30%' }} />
                <p className="d-inline ml-2">Linkedin</p>
              </a>
            </div>
          }

        </div>
      </div>  

      <div className="w-50 w-lg-100 mx-auto mt-5 mb-5">
        <hr className="my-0" />
      </div>

      {/* Photos */}
      <CarouselPhotos img1={img1} img2={img2} img3={img3}/>      

      {/* Services */}      
      {categories.length > 0 &&        
        <Fragment>
          <div className="w-50 w-lg-100 mx-auto mt-5 mb-5">
            <hr className="my-0" />
          </div>
          <Services categories={categories}/>
        </Fragment>      
      }
    </div>
  )
}
/*eslint-disable */
// Provide (Pages/Components/CompanyDetailContainer/index)