import React, { useState, useRef, useEffect } from 'react'
import { Layout } from '../../Layout';
import { Form } from './Form';
import axios from 'axios';
import { HOST } from '../../../Widgets/Utils';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

const NewCompany = ({ arrayCities }) => {

  const [form, setForm] = useState( 
    { name: '', phone: '', logo: '', city_id: '', address: '', web_page: '', facebook: '',
      twitter: '', instagram: '', linkedin: '', description: '', lat: '', lng: '', img1: '',
      img2: '', img3: '', email: '', password: '', password_confirmation: '',
      action_categories: [], action_cities: [], categories: [], cities: [] });
        
  let currentPath = window.location.pathname;
  const history = useHistory();    
  const [arrayCategories, setCategories] = useState([]);

  const Id = localStorage.getItem('client-hd');
  const isMountedRef = useRef(null);


  useEffect(() => {
    isMountedRef.current = true;
    const fetchData = async () => {
      axios.get(`${HOST}/categories`)
      .then(res => {if(isMountedRef.current){                     
          setCategories(res.data);
        }})
      .catch(err => console.log('Error', err));
    };      
    fetchData();
    
    return () => isMountedRef.current = false;
  }, []);

  useEffect(() => {
    isMountedRef.current = true;    
    if (Id !== null) {
      const fetchData = async () => {
        axios.get(`${HOST}/company?id=${Id}`)
        .then(res => {if(isMountedRef.current){            
            setForm(res.data);
          }})
        .catch(err => console.log('Error', err));
      };      
      fetchData();
    }    
    return () => isMountedRef.current = false;
  }, [Id]);

  const handleEdit = async e => {    
    e.preventDefault();
    await axios.put(`${HOST}/company?id=${Id}`, form)
    .then(res => {       
      history.replace(`${currentPath}/replace`);
      setTimeout(() => {
          history.replace(currentPath)
      }, 0)
    })
    .catch(err => {
      console.log('Error', err);
    });
  }

  const handleChange = (event) => {    
    setForm({...form, [event.target.name]: event.target.value })
  }

  const handleChangeCategories = (event, values) => {       
    event.preventDefault();
    setForm({...form, 'action_categories': values.map(el => el.id)})
  }

  const handleChangeCity = (event, values) => {
    event.preventDefault();
    setForm({...form, 'action_cities': values.map(el => el.id)})
  }

  const onDrop = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setForm({...form, 'logo': e.target.result})
    }
  };

  const onDropImg1 = (event) => {    
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setForm({...form, 'img1': e.target.result})
    }    
  };

  const onDropImg2 = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setForm({...form, 'img2': e.target.result})
    }        
  };

  const onDropImg3 = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setForm({...form, 'img3': e.target.result})
    }
  };
  
  return (
    <Layout>      
      <Form 
        initialState={form}
        handleEdit={handleEdit}
        handleChange={handleChange}
        onDrop={onDrop} 
        onDropImg1={onDropImg1}
        onDropImg2={onDropImg2}
        onDropImg3={onDropImg3}        
        arrayCategories={arrayCategories} 
        handleChangeCategories={handleChangeCategories}
        handleChangeCity={handleChangeCity} 
        arrayCities={arrayCities}/>
    </Layout>
  )
}

const mapStateToProps = (state) => {  
  return {
    arrayCities: state.cities_reducer,
  }
}

export default connect(mapStateToProps, null)(NewCompany);

// Provide(SuperAdmin/Components/Companies)
