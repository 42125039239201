import React from 'react';
import { AiOutlineMail } from "react-icons/ai";

export const Instructions = () => (
  <main id="content">  
    <div className="d-md-flex align-items-md-center height-100vh--md">
      <div className="container text-center space-2 space-3--lg">
        <div className="w-md-80 w-lg-60 text-center mx-md-auto">
          <div className="mb-5">
            <span className="u-icon u-icon--secondary u-icon--xl rounded-circle mb-4">
              <AiOutlineMail className="u-icon__inner"/>
            </span>
            <h1 className="h2">Envio instrucciones</h1>
            <p>Las instrucciones fueron enviadas correctamente a tu correo electronico</p>            
            Nota: En caso de no revisar las instrucciones en la bandeja de entrada revisa el spam
          </div>
        </div>
      </div>
    </div>
  </main>
)