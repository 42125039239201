import React, { Fragment, useState } from 'react';
// SnackBar
import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// axios
import axios from 'axios';
import { HOST } from '../../../Widgets/Utils';
// form react
import { Form } from './Form';
import { useForm } from "react-hook-form";
import { ContactSend } from './ContactSend';

export const Contact = ({ contact_title, contact_subtitle }) => {

  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [contact, setContact] = useState(false);

  const onSubmit = async data => {
    setContact(true);
    await axios.post(`${HOST}/contact_u`, data)
    .then(res => {      
      setMessageInfo("Mensaje enviado exitosamente");
      setOpen(true);
    })
    .catch(err => {
      setMessageInfo("No fue posible enviar el mensaje intente nuevamente");
      setOpen(true);
    }) 
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeContact = () => {
    setContact(false)
  };
  
  return (
    <Fragment>      
      <div className="container space-2 space-3--lg">      
        <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">          
          <h2 className="h3">{contact_title}</h2>
          <p>{contact_subtitle}</p>
        </div>
      
        {!contact ? 
          <div className="w-lg-80 mx-auto">          
            <Form handleSubmit={handleSubmit(onSubmit)} 
                register={register}
                errors={errors} />          
          </div>
          :          
          <ContactSend handleChangeContact={handleChangeContact}/>          
        }
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={messageInfo}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

    </Fragment>

  )
}

// Provide(Pages/Components/Home)
