/*eslint-disable */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export const Links = ({ textColor }) => {
  return (
    <Fragment>
      <li className="nav-item hs-has-sub-menu u-header__nav-item mr-4">
        <Link to="/" className={`link-primary ${textColor}`}>
          Inicio
        </Link>
      </li>

      <li className="nav-item hs-has-sub-menu u-header__nav-item mr-4">
        <Link to="/sobre-nosotros" className={`link-primary ${textColor}`}>
          Sobre nosotros
        </Link>
      </li>
  </Fragment>
  )
}
/*eslint-disable */