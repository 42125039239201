import React from 'react'

export const Suscribe = () => {
  return (
    <div className="container position-relative z-index-2 mb-n9">
      <div className="w-md-80 w-lg-60 mx-md-auto">
        <div className="bg-primary shadow-lg text-center rounded bg-img-hero p-9" >
          <div className="mb-5">
            <h2 className="h3 text-white">Ready to build your business on Space?</h2>
            <p className="text-light">Let us guide you through our products and help you get started.</p>
          </div>
          <a className="btn btn-wide btn-light mb-1 mb-sm-0 mr-sm-2" href="https://themes.getbootstrap.com/product/space-multipurpose-responsive-template/">Get Started</a>
          <a className="btn btn-wide btn-outline-light mb-1 mb-sm-0" href="https://themes.getbootstrap.com/product/space-multipurpose-responsive-template/">Sign Up</a>
        </div>
      </div>
    </div> 
  )
}