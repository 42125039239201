import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  image: {    
    width: '100%',
    objectFit: 'contain',
    height: '100%',
    backgroundColor: '#f9f9f9eb'
  },
  background: {
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%), url(https://i.ytimg.com/vi/TguAdPDx34M/maxresdefault.jpg)`    
  },
  grid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '50px 1fr'
  },
  imageDetail: {
    height: 'auto'
  },
  list: {
    display: 'flex',
    alignItems: 'center'
  },
  ul : {
    margin: 'initial'
  },
  li: {
    display: 'inline'
  },
  div : {
    minHeight: '200px'
  }  
}))