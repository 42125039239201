import React from 'react';
import { styles } from './styles'
import { RiMessage2Line } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { TiLocationOutline } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';

export const ItemCompany = ({ company } ) => {

  const { id, address, phone, city, description, logo, 
          name, web_page } = company
  
  const clases = styles();
  return (    
    <Reveal effect="fadeInUp">
      <div className={`container mb-4 ${clases.div}`}>    
        <div className="card-deck d-block d-lg-flex">
          <article className="card border-0 mb-5">
            <div className="card-body row align-items-stretch no-gutters p-0">
              {/* <!-- News Blog Card --> */}
              <div className={`col-4 card-img-right border border-left-0 bg-img-hero ${clases.imageDetail} ` }>
                <img src={logo} className={clases.image} alt="" />
              </div>
              <div className="col-8 border border-right-0 rounded-left">
                {/* Title and description */}
                <div className="p-5">
                  <h2 className="h5 mb-3">
                    {name}
                  </h2>
                  <p className="mb-0">{description}</p>
                </div>
                {/* Location with icon */}
                <div className={clases.grid}>
                  <div className="ml-5">
                    <TiLocationOutline size='28px'/>
                  </div>
                  <div className="ml-4">
                    <p className="mb-0">{city} - {address}</p>
                  </div>
                </div>
                {/* Web Page */}
                <div className="p-5">
                  <p className="mb-0">{web_page}</p>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row">
                <div className={`col-md-8 ${clases.list}`}>
                  {phone && 
                    <ul className={clases.ul}>                    
                      <li className={clases.li}>
                        <a className="link-primary" href={`https://api.whatsapp.com/send?phone=57${phone}`} target="_blank" rel="noopener noreferrer">
                          <span>
                            <RiMessage2Line size='28px' /> 
                            <span className="ml-2">Mensaje</span>
                          </span>
                        </a>
                      </li>

                      <li className={`ml-9 ${clases.li}`}>
                        <a className="link-primary" href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
                          <FiPhoneCall size='28px' /> 
                          <span className="ml-2">Teléfono</span>
                        </a>
                      </li>                    
                    </ul>
                  }
                </div>
                <div className="col-md-2 offset-md-2">
                  <Link className="btn btn-outline-primary" to={`/empresa/${id}`}>
                    Ver detalles
                  </Link>
                </div>
              </div>
            </div>
          </article>
        </div>        
      </div>
    </Reveal>   
  )
}