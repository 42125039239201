import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  divCarousel: {
    width: '350px',
    height: '350px'
  },
  imageCarousel: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    margin: 'auto'
  }, 
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'    
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%'
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  input : {
    width: '100%'
  },
  close: {
    padding: theme.spacing(0.5),
  }
}))