import React, { Fragment } from 'react'
import { TextField } from '@material-ui/core';
import { styles } from './styles'

export const Form = ( { initialState, handleChange, handleSubmit } ) => {

  const classes = styles();

  const { name, email, phone, message} = initialState
  const formRef = React.useRef();

  return (
    <Fragment>
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <h2 className="h3">Mensaje de contacto</h2>
        <p>Envianos un mensaje y en pronto nos contactaremos contigo</p>
      </div>
                
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9 row">
        <div className="col-md-12">
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                        
            <TextField
              id="outlined-basic"
              name="name"
              required
              value={name}
              autoComplete="off"
              label="Nombre"               
              variant="outlined" 
              onChange={handleChange}
              className={`mb-4 ${classes.input}`}
              />

            <TextField
              name="email"
              required
              value={email}
              id="outlined-basic" 
              type="email" 
              label="Email"
              onChange={handleChange}
              variant="outlined" 
              className={`mb-4 ${classes.input}`}
              />

            <TextField
              required
              name="phone"                
              value={phone}
              id="outlined-number"
              label="Numero de contacto" 
              type="number"
              variant="outlined" 
              onChange={handleChange}
              className={`mb-4 ${classes.input}`}
              />

            <TextField 
              name="message"              
              value={message}
              id="outlined-multiline-static"
              label="Mensaje"
              multiline
              rows={4}
              variant="outlined"    
              onChange={handleChange}
              className={`${classes.input}`}
              />
              
              <button onClick={() => formRef.current.reportValidity()} className="btn btn-primary">Contactar</button>
          </form>      
        </div>
      </div>

    </Fragment>
  )
}

// Provide(Components/CompanyDetailContainer/Contact)