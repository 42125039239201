export const HOST = (() => {  
  switch (window.location.hostname) {
    case "www.heroku.com":
      return "https://www.heroku.com";
    case "www.digitalocean.com":
      return "https://app.digitalocean.com";      
    case "qa-www.amazon.com":
      return "https://qa.amazon.com";      
    case "huella-digital.vercel.app":
      return "https://api-huella-digital.herokuapp.com";
    case "amazing-leavitt-b50ea3.netlify.app":
      return "https://api-huella-digital.herokuapp.com";
    case "directoriohuelladigital.com":
      return "https://api-huella-digital.herokuapp.com";
    default:
      return "http://localhost:3000";
  }
})();