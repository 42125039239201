import React, { useEffect, useRef, useState, useReducer } from 'react';
import axios from 'axios';
import { Reducer as reducer } from '../../../Widgets/Reducer';
import { Form } from './Form';
import { HOST } from '../../../Widgets/Utils';
import { connect } from 'react-redux';
import { setFilter } from '../../../redux/actions/filterActions';
import { useHistory } from "react-router-dom";

const PrincipalFilter = ({ cities, setFilter }) => {

  const initialState = {
    city_id: '',
    category_id: '',
  }

  const history = useHistory();
  const [form, dispatch] = useReducer(reducer, initialState);
  const isMountedRef = useRef(null);
  const [categories, setCategories] = useState([]);  


  useEffect(() => {
    isMountedRef.current = true;        
    const fetchData = async () => {
      axios.get(`${HOST}/categories`)
      .then(res => {if(isMountedRef.current){           
          setCategories(res.data);
        }})
      .catch(err => console.log('Error', err));
    };      
    fetchData();
    
    return () => isMountedRef.current = false;
  }, []);

  const handleChangeCategory = (e, newValue) => {    
    if (newValue != null) {
      dispatch({ field: 'category_id', value: newValue.id });      
    }
  }  

  const handleChangeCity = (e, newValue) => {    
    if (newValue != null) {
      dispatch({ field: 'city_id', value: newValue.id });
    }    
  }  
  
  const handleSubmit = async e => {
    e.preventDefault()    
    await axios.get(`${HOST}/companies?category_id=${form.category_id}&city_id=${form.city_id}`)
    .then(res => {
      setFilter(res.data);
      history.push(`/lista-empresas`);
    })
    .catch(err => console.log('Error', err));
  }
    
  return (    
      <div className="bg-white z-index-2">
        <div className="container space-1">
          {/* <!-- Job Positions - Filter --> */}
            <Form initialState={form}
                  handleSubmit={handleSubmit} 
                  handleChangeCategory={handleChangeCategory}
                  handleChangeCity={handleChangeCity}
                  cities={cities}
                  categories={categories}/>          
        </div>        
      </div>
  )
}


const mapDispatchToProps = {
  setFilter
}

const mapStateToProps = (state) => {  
  return {
    cities: state.cities_reducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrincipalFilter);