import React from 'react';
import Reveal from 'react-reveal/Reveal';

export const Benefits = ({ benefits_title, benefits_description, icon_benefit_one, title_benefit_one, description_benefit_one,
                           icon_benefit_two, title_benefit_two, description_benefit_two, icon_benefit_three, title_benefit_three, description_benefit_three}) => {
  
  return (
  <div className="bg-gray-100">
    <div className="container space-2 space-3--lg">
      <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-9">                
        <h2 className="h3">{benefits_title}</h2>
        <p>{benefits_description}</p>
      </div>

      <div className="row">
        <div className="col-md-4 mb-7 mb-md-0">
          {/* <!-- Icon Blocks --> */}
          <Reveal effect="fadeInUp">
            <div className="text-center px-lg-3">
              <img className="max-width-14 mb-2" src={icon_benefit_one} alt=""/>
              <h3 className="h4">{title_benefit_one}</h3>
              <p className="mb-0">{description_benefit_one}</p>
            </div>
          </Reveal>
          {/* <!-- End Icon Blocks --> */}
        </div>

        <div className="col-md-4 mb-7 mb-md-0">
          {/* <!-- Icon Blocks --> */}
          <Reveal effect="fadeInUp">
            <div className="text-center px-lg-3">
              <img className="max-width-14 mb-2" src={icon_benefit_two} alt=""/>
              <h3 className="h4">{title_benefit_two}</h3>
              <p className="mb-0">{description_benefit_two}</p>
            </div>
          </Reveal>
          {/* <!-- End Icon Blocks --> */}
        </div>

        <div className="col-md-4">
          {/* <!-- Icon Blocks --> */}
          <Reveal effect="fadeInUp">
            <div className="text-center px-lg-3">
              <img className="max-width-14 mb-2" src={icon_benefit_three} alt=""/>
              <h3 className="h4">{title_benefit_three}</h3>
              <p className="mb-0">{description_benefit_three}</p>
            </div>
          </Reveal>
          {/* <!-- End Icon Blocks --> */}
        </div>
      </div>
    </div>
  </div>
  )
}
