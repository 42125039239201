import React from 'react';
import { useForm } from 'react-hook-form';
import ImageUploader from "react-images-upload";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const Form = ({ initialState, handleEdit, handleChange, onDrop, onDropImg1, onDropImg2, onDropImg3,
                      arrayCities, arrayCategories, handleChangeCategories, handleChangeCity }) => {

  const { register, errors } = useForm();    
  const { name, phone, address, web_page, facebook, twitter, instagram, linkedin,
    description, email, password, password_confirmation, categories, cities } = initialState;    
    
  return (
      <form onSubmit={handleEdit} autoComplete="off">        
        <div className="container">
          <div className="row">
            <div className="col-md-4">

              {/* Email */}
              <input className="form-control form__input mb-4" 
                   type="email"
                   placeholder="Correo electronico" 
                   name="email" 
                   value={email || '' }
                   autoComplete="off"                    
                   onChange={handleChange}
                   ref={register({
                    required: "required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Entered value does not match email format"
                    }
                  })}
                   />
                  {errors.email && <span role="alert">{errors.email.message}</span>}

              
              <input name="name"
                placeholder="Nombre empresa"
                onChange={handleChange}
                value={name || ''}
                className="form-control form__input mb-4"                 
                ref={register({ required: true, maxLength: 30 })} />

              <input
                name="web_page"
                onChange={handleChange}
                value={web_page || ''}
                placeholder="Url pagina web" 
                className="form-control form__input mb-4" 
                 />

              <input
                name="twitter"
                onChange={handleChange}
                value={twitter || ''}
                placeholder="Twitter"
                className="form-control form__input mb-4" 
                 />

              <input
                name="linkedin"
                onChange={handleChange}
                value={linkedin || ''}
                placeholder="Linkedin"
                className="form-control form__input mb-4" 
                />

            </div>            
            {/* Second Column */}
            <div className="col-md-4">
            <input className="form-control form__input mb-4" 
                   type="password" 
                   placeholder="Contraseña" 
                   name="password"
                   onChange={handleChange}
                   value={password || ''}
                   ref={register({
                    required: "required",
                    minLength: {
                      value: 5,
                      message: "min length is 5"
                    }
                  })}/>
                  {errors.password && <span role="alert">{errors.password.message}</span>}

            <input className="form-control form__input mb-4" 
                type="password" 
                placeholder="Confirmar contraseña" 
                name="password_confirmation"
                onChange={handleChange}
                value={password_confirmation || ''}
                ref={register({
                required: "required",
                minLength: {
                  value: 5,
                  message: "min length is 5"
                }
              })}/>
              {password !== password_confirmation && <span role="alert">Contraseña debe ser igual</span>}
              
              <input
                name="phone"
                onChange={handleChange}
                value={phone || ''}
                placeholder="Telefono"
                className="form-control form__input mb-4" 
                ref={register({ required: true })} />

              <input
                name="instagram"
                onChange={handleChange}
                value={instagram || ''}
                placeholder="Instagram"
                className="form-control form__input mb-4" 
                />

              <input
                style={{ marginTop: '34px' }}
                name="facebook"
                onChange={handleChange}
                value={facebook || ''}
                placeholder="Facebook"
                className="form-control form__input mb-4" 
                />

            </div>   

            <div className="col-md-4">

              <input type="file" 
                  className="form-control" 
                  name="logo" 
                  onChange={onDrop} />

              <input style={{ marginTop: '50px' }}
                name="address"
                onChange={handleChange}
                value={address || ''}
                placeholder="Direccion"
                className="form-control form__input" 
                ref={register({ required: true })} />

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <textarea 
                  name="description"
                  onChange={handleChange}
                  value={description || ''}
                  placeholder="Quienes somos"
                  className="form-control form__input mb-4" 
                  rows="4" 
                  cols="50" />              
            </div>
          </div>


          {/* Category_id and City_id */}
          {/* maps */}
          <div className="col-md-12">
            <h6 className="text-center">Ingresa tu ciudad y categorias de actuación</h6>
            <div className="row mt-4">
              <div className="col-md-6">
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={arrayCities}
                    onChange={handleChangeCity}
                    getOptionLabel={(option) => option.name}                  
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Seleccionar ciudades de actuación"
                        placeholder="Ciudades"
                      />
                    )}
                  />

                <List component="nav" aria-label="secondary mailbox folders">
                  {cities.map((city)=>{      
                    return(
                      <ListItem button key={city.id}>
                        <ListItemText primary={city.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>

              <div className="col-md-6">
                <Autocomplete
                  multiple
                  id="tags-outlined-categories"
                  options={arrayCategories}
                  onChange={handleChangeCategories}
                  getOptionLabel={(option) => option.name}                  
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seleccionar categorias de actuación"
                      placeholder="Categorias"
                    />
                  )}
                />                
                <List component="nav" aria-label="secondary mailbox folders">
                  {categories.map((category)=>{      
                    return(
                      <ListItem button key={category.id}>
                        <ListItemText primary={category.name} />                    
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </div>


          {/* Imagenes */}

          <div className="col-md-12 mt-5">
            <h6 className="text-center">Imagenes de servicios o productos</h6>
            <div className="row mt-4">
              
              <div className="col-md-4">
                <ImageUploader
                  name="img1"
                  onChange={onDropImg1}
                  withIcon={true}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                />
              </div>

              <div className="col-md-4">
                <ImageUploader
                    name="img2"
                    onChange={onDropImg2}
                    withIcon={true}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                  />
              </div>              

              <div className="col-md-4">
                <ImageUploader
                    name="img3"
                    onChange={onDropImg3}
                    withIcon={true}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                  />
              </div>            
            </div>
          </div>          
        </div>          
        <div className="text-center mt-4 mb-9">
          <button className="btn btn-primary">Actualizar empresa</button>        
        </div>
      </form>    
  )
}


