import { ADD_FILTER } from '../actions/filterActions';

const initial_state = [];

function reducerSetFilter(state = initial_state, action) {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        initial_state: action.payload
      }
    default:
      return state;
  }
}

export default reducerSetFilter;