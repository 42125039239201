import React from 'react';
import { Link } from 'react-router-dom';

export const PageError = () => (
  <main id="content">
    <div className="bg-img-hero" style={{ backgroundImage: `url(/images/maintenance/img17.jpg)` }}>
      <div className="d-sm-flex align-items-sm-center height-100vh--sm">
        <div className="container text-center space-3-top space-2-bottom space-3-bottom--lg">
          <div className="w-lg-60 text-center mx-lg-auto">
            <div className="mb-9">
              <h1 className="display-3 font-size-48--md-down font-weight-medium">Estamos teniendo problemas</h1>
              <p className="lead">Trabajamos para brindarte una mejor experiencia.</p>
            </div>
            <Link to="/" className="btn btn-block btn-primary"> Ir al inicio </Link>  
          </div>
        </div>
      </div>
    </div>  
  </main>
)