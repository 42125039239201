import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import List from '@material-ui/core/List';
import BusinessIcon from '@material-ui/icons/Business';
import { useHistory } from "react-router-dom";

export const Links = () => {
  const history = useHistory();


  const exitAdmin = () => {
    localStorage.removeItem('authentication_token');
    localStorage.removeItem('client-hd');
    history.push(`/`);
  }

  return (

    <Fragment>
      <Link to="/administrador" style={{color: '#212121'}}>
        <List>
          <ListItem button>
            <ListItemIcon><MailOutlineIcon /></ListItemIcon>
            <ListItemText primary="Contactos" />
          </ListItem>
        </List>
      </Link>   

      <Link to="/administrar-empresa" style={{color: '#212121'}}>
        <List>
          <ListItem button>
            <ListItemIcon><BusinessIcon /></ListItemIcon>
            <ListItemText primary="Ver empresa" />
          </ListItem>
        </List>
      </Link>

      <span onClick={() => { exitAdmin()}}>
        <List>
          <ListItem button>
            <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
            <ListItemText primary="Cerrar sesion" />
          </ListItem>
        </List>
      </span>


    </Fragment>
  )
}
