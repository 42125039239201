import React from 'react';
import Zoom from 'react-reveal/Zoom';

export const Information = ({title_body, description_body, component_image_one, component_title_one, component_description_one, 
  component_image_two, component_title_two, component_description_two, component_image_three, component_title_three, component_description_three,
  component_image_four, component_title_four, component_description_four}) => {  
  return (
    <div className={`container position-relative space-2`}>
      <div className="row ">
        <div className="col-lg-5 mb-9 mb-lg-0">
          <div className="pr-lg-4">
            {/* <!-- Title --> */}
            <h2 className="h3">{title_body}</h2>
            <p>{description_body}</p>            
            {/* <!-- End Title --> */}
          </div>
        </div>

        <div className="col-lg-7">
          <div className="row">
            
              <Zoom left>
                <div className="col-md-6 mb-7">
                  {/* <!-- Icon Blocks --> */}
                  <img className="max-width-10 mb-2" src={component_image_one} alt="" />
                    <h3 className="h5">{component_title_one}</h3>
                  <p className="mb-0">{component_description_one}</p>
                  {/* <!-- End Icon Blocks --> */}
                </div>
              </Zoom> 

              <Zoom left>
                <div className="col-md-6 mb-7">
                  {/* <!-- Icon Blocks --> */}
                  <img className="max-width-10 mb-2" src={component_image_two} alt="" />
                  <h3 className="h5">{component_title_two}</h3>
                  <p className="mb-0">{component_description_two}</p>
                  {/* <!-- End Icon Blocks --> */}
                </div>
              </Zoom>

              <Zoom left>
                <div className="col-md-6 mb-7 mb-md-0">
                  {/* <!-- Icon Blocks --> */}
                  <img className="max-width-10 mb-2" src={component_image_three} alt="" />              
                  <h3 className="h5">{component_title_three}</h3>
                  <p className="mb-0">{component_description_three}</p>
                  {/* <!-- End Icon Blocks --> */}
                </div>
              </Zoom>

              <Zoom left>
                <div className="col-md-6">
                  {/* <!-- Icon Blocks --> */}
                  <img className="max-width-10 mb-2" src={component_image_four} alt="" />
                  <h3 className="h5">{component_title_four}</h3>
                  <p className="mb-0">{component_description_four}</p>
                  {/* <!-- End Icon Blocks --> */}
                </div>
              </Zoom>
          </div>
        </div>
      </div>

      <img className="d-none d-lg-block w-lg-50 position-absolute-bottom-left-0 z-index-minus-1" src='/images/background/bg3.png' alt="" />
    </div>

  )
}

// Provide(Pages/Components/AboutUs/index)