import React, { useEffect, useRef, useState } from 'react';
import { Categories } from './Categories';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import { HOST } from '../../Widgets/Utils';
import { connect } from 'react-redux';
import { setFilter } from '../../redux/actions/filterActions';
import { useHistory } from "react-router-dom";

const Hero = ({ title_main, description_main, photo_slider, setFilter }) => {

  const isMountedRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {    
    isMountedRef.current = true;        
    const fetchData = async () => {
      axios.get(`${HOST}/is_category`)
      .then(res => {if(isMountedRef.current){            
          setCategories(res.data);          
        }})
      .catch(err => console.log('Error', err));
    };      
    fetchData();  
    return () => isMountedRef.current = false;
  },[]); 


  const handleFilter = async category_id => {    
    await axios.get(`${HOST}/companies?category_id=${category_id}`)
    .then(res => {      
      setFilter(res.data);
      history.push(`/lista-empresas`);
    })
    .catch(err => console.log('Error', err));
  }

  return (    
    <div className="bg-img-hero" style={{ backgroundImage: `url(${photo_slider})` }}>
      <div className="d-lg-flex align-items-lg-center flex-lg-column">
        <div className="container space-3 space-4-top--lg">
          <Zoom left cascade>
            <div className="w-md-50">
              <h1 className="display-4 font-size-48--md-down text-white">{title_main}</h1>
              <p className="lead text-white">{description_main}</p>
            </div>
          </Zoom>          
        </div>
      </div>
      {categories !== null &&
        <Categories handleFilter={handleFilter} 
                    categories={categories} />  
      }
    </div>
)}

const mapDispatchToProps = {
  setFilter
}

export default connect(null, mapDispatchToProps)(Hero);

// Provide ( Components/Home )