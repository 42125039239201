import React, { Fragment } from 'react'

export const About = ({ description }) => {
  return (
    <Fragment>
      <h3 className="mb-3"> Acerca de la empresa </h3>
      <p className="mb-3" style={{ maxWidth: '545px' }}>{description}</p>
    </Fragment>
  )
}

// Provide(Pages/Components/CompanyDetail)