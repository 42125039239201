import React, { Fragment } from 'react';
import Slider from "react-slick";
import { styles } from './styles';

export const CarouselPhotos = ({ img1, img2, img3 }) => {

  const clases = styles()

  const initial = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true
  };

  return (
    <Fragment>
      <h3 className="mb-3"> Fotos </h3>
      <div className={clases.divCarousel}>        
        <Slider {...initial}>          
            <div>
              <img className={clases.imageCarousel} src={img1} alt=""/>
            </div>
            <div>
              <img className={clases.imageCarousel} src={img2} alt=""/>
            </div>
            <div>
              <img className={clases.imageCarousel} src={img3} alt=""/>
            </div>
        </Slider>
      </div>
    </Fragment>
  )
}
// Provide(Pages/Components/CompanyDetail)